<script setup lang="ts">
const { t, locale } = useI18n({ useScope: 'local' })
const localePath = useLocalePath()
</script>

<template>
  <div class="fixed inset-x-0 h-10 text-white transition-colors bg-violet-700 z-navbar hover:bg-violet-900">
    <div class="flex items-center justify-center w-full h-full px-4 md:justify-start lg:justify-center">
      <NuxtLink data-x-name="dev_competition" :to="localePath('competition')" class="font-semibold tracking-[0.01em] font-display text-[0.6871rem] xs:text-xs text-center md:text-sm md:leading-7">
        <span class="relative z-10 hidden md:block">
          <template v-if="locale === 'fr'">
            Développeurs d’appli? Gagnez une évaluation et un mentorat
            <span class="text-blue-400">ISO 82304-2</span>
            <span class="text-dev"> valeur de 25000$</span>
          </template>
          <template v-else>
            App Developers? Win your
            <span class="text-blue-400">ISO 82304-2</span>
            assessment and mentorship,
            <span class="inline-block text-dev"> worth $25,000</span>
          </template>
        </span>
        <span class="relative z-10 md:hidden">
          {{ t('text[0]') }}
          <span class="text-dev">{{ t('text[1]') }}</span>
        </span>

        <span class="absolute inset-0" />
      </NuxtLink>

      <img
        src="/assets/competition/bg-banner-desktop.svg"
        width="180"
        height="40"
        aria-hidden="true"
        class="absolute right-0 hidden lg:block"
      >
      <img
        src="/assets/competition/bg-banner-mobile.svg"
        width="100"
        height="40"
        aria-hidden="true"
        class="absolute right-0 hidden sm:block lg:hidden"
      >
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "text": [
      "App Developer?",
      "Get a chance to win $25,000"
    ]
  },
  "fr": {
    "text": [
      "Développeurs d’appli?",
      "Courez la chance de gagner 25000$ en prix"
    ]
  }
}
</i18n>
